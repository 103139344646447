.engoc-row-equal {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

@media screen and (min-width: 1200px) {
  .engoc-row-equal-section-new-product-v2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; } }
/* Custom Fonts */
/* Custom Maxwidth Logo */
/* Custom Label Product */
/* Custom Header Nav */
/*========== Custom Label Product ==========*/
.engoc_new_label {
  color: white;
  background-color: #8bd485;
  font-size: 10px; }

.engoc_hot_label {
  color: white;
  background-color: #e65f5f;
  font-size: 10px; }

.engoc_sale_label {
  color: white;
  background-color: #f1ab5f;
  font-size: 10px; }

.engoc_popular_label {
  color: white;
  background-color: #529be0;
  font-size: 10px; }

/*========== Custom Header Nav ==========*/
.engoc-header-nav-s1 {
  background-color: #242626; }
  .engoc-header-nav-s1 .awemenu {
    background-color: #242626; }
    .engoc-header-nav-s1 .awemenu > .awemenu-item > a {
      color: black; }
  .engoc-header-nav-s1 .dropdown > a {
    color: black; }

.engoc-header-nav-s1 .dropdown {
  border-right: 1px solid #3a3a3a !important; }

.engoc-header-nav-s1 .navbar-icons {
  border-bottom: 1px solid #3a3a3a;
  border-left: 1px solid #3a3a3a; }

.engoc-header-nav-s1 .navbar-icons > .dropdown {
  border-left: 1px solid #3a3a3a; }

.engoc-header-nav-s1.awemenu-sticky {
  border-bottom: 1px solid #3a3a3a; }

.engoc-header-nav-s1.awemenu-sticky .awemenu {
  border-bottom: 1px solid #3a3a3a; }

@media (min-width: 480px) {
  .engoc-header-nav-s1 .navbar-icons {
    border-bottom: none; } }
.engoc-header-nav-s2 {
  background-color: white; }
  .engoc-header-nav-s2 .awemenu {
    background-color: white; }
    .engoc-header-nav-s2 .awemenu > .awemenu-item > a {
      color: #242626; }
  .engoc-header-nav-s2 .dropdown > a {
    color: #242626; }

.engoc-header-nav-s2 .navbar-icons .dropdown {
  border-right: 1px solid #e3e3e3; }

.engoc-header-nav-s2 .awemenu-container {
  border: 1px solid #e3e3e3;
  border-top: none; }

@media (min-width: 1200px) {
  .engoc-header-nav-s2 .awemenu-container {
    border-right: none; } }
.engoc-header-nav-s3 {
  background-color: white; }
  .engoc-header-nav-s3 .awemenu {
    background-color: white;
    z-index: 999999; }
    .engoc-header-nav-s3 .awemenu > .awemenu-item > a {
      color: #242626; }

.engoc-header-s3 .br-nav-wrapper {
  background-color: #f9f9f9; }
  .engoc-header-s3 .br-nav-wrapper .dropdown > a {
    color: #242626; }

.awemenu-nav .awemenu-submenu {
  background-color: white; }
  .awemenu-nav .awemenu-submenu h1, .awemenu-nav .awemenu-submenu h2, .awemenu-nav .awemenu-submenu h3, .awemenu-nav .awemenu-submenu h4, .awemenu-nav .awemenu-submenu h5, .awemenu-nav .awemenu-submenu h6 {
    color: #242626; }
  .awemenu-nav .awemenu-submenu a {
    color: #999999; }

/*========== Custom Slider ==========*/
.slider-main-content {
  position: relative;
  z-index: 9999;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  top: 50%;
  left: 0;
  width: 100%; }

svg:not(:root) {
  overflow: hidden; }

.placeholder-background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }
  .placeholder-background .placeholder-svg {
    display: block;
    fill: rgba(120, 129, 136, 0.35);
    background-color: rgba(120, 129, 136, 0.1);
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    border: 1px solid rgba(120, 129, 136, 0.2); }

.slideshow_overlay {
  height: 100%; }
  .slideshow_overlay:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #3d4246;
    opacity: 0.25;
    z-index: 3; }

/*========== Custom Fonts ==========*/
body {
  font-family: Montserrat, sans-serif; }

.tooltip {
  font-family: Montserrat, sans-serif; }

.popover {
  font-family: Montserrat, sans-serif; }

.br-slogan-note {
  font-family: Montserrat, sans-serif; }

.comment__content h4 {
  font-family: Montserrat, sans-serif; }

.br-product__name {
  font-family: Montserrat, sans-serif; }

h1, h2, h3, h4, h5, h6, .h1, .br-title, .h2, .nav-tabs > li > a, .h3, .br-product--list .br-product__price, .h4, .h5, .cart-total, .post-time, .h6, .br-cat a, .br-form-checkout label:not(.c-input) {
  font-family: Raleway, sans-serif; }

.small {
  font-family: Raleway, sans-serif; }

.uppercase {
  font-family: Raleway, sans-serif; }

.font-third {
  font-family: Raleway, sans-serif; }

.br-play .tooltip-inner {
  font-family: Raleway, sans-serif; }

.br-logo-wrapper {
  font-family: Raleway, sans-serif; }

.br-site-info {
  font-family: Raleway, sans-serif; }

blockquote .small {
  font-family: Raleway, sans-serif; }

.br-lookbook-wrapper .select2-container .select2-selection--single .select2-selection__rendered,
.br-lookbook-wrapper .select2-results__option {
  font-family: Raleway, sans-serif; }

.br-register .btn, .br-register button, .br-register
input[type="submit"], .br-register
input[type="reset"], .br-register
input[type="button"], .br-login .btn, .br-login button, .br-login
input[type="submit"], .br-login
input[type="reset"], .br-login
input[type="button"] {
  font-family: Raleway, sans-serif; }

.br-login .lost_password {
  font-family: Raleway, sans-serif; }

.c-input {
  font-family: Raleway, sans-serif; }

.dropdown .count {
  font-family: Raleway, sans-serif; }

.alert > strong {
  font-family: Raleway, sans-serif; }

.filters a {
  font-family: Raleway, sans-serif; }

.br-filter-wrapper {
  font-family: Raleway, sans-serif; }

.br-product__label {
  font-family: Raleway, sans-serif; }

.br-product__price {
  font-family: Raleway, sans-serif; }

.sold-out {
  font-family: Raleway, sans-serif; }

.sold-out:after, .sold-out:before {
  font-family: Raleway, sans-serif; }

.product-mini__img span {
  font-family: Raleway, sans-serif; }

.in-stock {
  font-family: Raleway, sans-serif; }

.entry-meta-list li {
  font-family: Raleway, sans-serif; }

.textwidget {
  font-family: Raleway, sans-serif; }

.widget ul li a,
.widget ol li a {
  font-family: Raleway, sans-serif; }

.tagcloud a {
  font-family: Raleway, sans-serif; }

.widget_latest_tweets .link_tweets {
  font-family: Raleway, sans-serif; }

.widget-text form .btn, .widget-text form button, .widget-text form
input[type="submit"], .widget-text form
input[type="reset"], .widget-text form
input[type="button"] {
  font-family: Raleway, sans-serif; }

.awemenu a {
  font-family: Raleway, sans-serif; }

.classic-title {
  font-family: Raleway, sans-serif; }

.classic-list li {
  font-family: Raleway, sans-serif; }

.br-board {
  font-family: Raleway, sans-serif; }

.br-countdown {
  font-family: Raleway, sans-serif; }

.engo-promotion-countdown {
  font-family: Raleway, sans-serif; }

.font-secondary {
  font-family: Montserrat, serif; }

.font--secondary {
  font-family: Montserrat, serif; }

.br-slogan {
  font-family: Montserrat, serif; }

.heading-page {
  font-family: Montserrat, serif; }

blockquote {
  font-family: Montserrat, serif; }

blockquote:before {
  font-family: Montserrat, serif; }

legend {
  font-family: Montserrat, serif; }

.entry-title {
  font-family: Montserrat, serif; }

.entry-media-link {
  font-family: Montserrat, serif; }

.br-lookbook__name {
  font-family: Montserrat, serif; }

.br-card-categories__title span {
  font-family: Montserrat, serif; }

.section-404 h1 {
  font-family: Montserrat, serif; }

.section-404 h2 {
  font-family: Montserrat, serif; }

.br-commingsoon__main > h1 {
  font-family: Montserrat, serif; }

br-countdown strong {
  font-family: Montserrat, serif; }

.font-four {
  font-family: Hind, sans-serif; }

/*========== Custom Quanity Product Detail & Cart ==========*/
.engoc-quantity button {
  padding: 0px;
  margin-right: 0px;
  height: 37px !important;
  min-width: 37px;
  line-height: 37px !important;
  background-color: #efefef !important;
  color: #333; }
  .engoc-quantity button:hover, .engoc-quantity button:focus {
    background-color: #666 !important;
    color: #fff; }

.engoc-quantity span.icon {
  margin-left: 0px; }

.engoc-cart-qty button {
  background-color: transparent !important; }
  .engoc-cart-qty button:hover, .engoc-cart-qty button:focus {
    background-color: transparent !important; }

/*========== Custom MaxWidth Logo ==========*/
.engoc-h1-maxwidth-logo {
  max-width: 170px; }

.engoc-h2-maxwidth-logo {
  max-width: 200px; }

.engoc-h3-maxwidth-logo {
  max-width: 200px; }

/* ========================================
11. Widgets Filter by Price, Size, Color
======================================== */
.widget-container {
  margin-bottom: 40px;
  padding-bottom: 5px;
  font-size: 14px; }
  .widget-container:last-child {
    border: none; }
  .widget-container .widget-title {
    font-size: 18px;
    margin: 0 0 30px;
    letter-spacing: 0.075em; }
    .widget-container .widget-title .clear {
      float: right;
      line-height: 15px;
      text-transform: none;
      color: #fe5556;
      font-size: 11px;
      font-family: arial;
      font-weight: normal;
      line-height: 28px; }
  .widget-container.widget_nav_menu ul, .widget-container.widget_categories ul, .widget-container.widget_product_categories ul, .widget-container.widget_recent_entries ul {
    margin: 0;
    padding: 0;
    list-style: none; }
    .widget-container.widget_nav_menu ul li, .widget-container.widget_categories ul li, .widget-container.widget_product_categories ul li, .widget-container.widget_recent_entries ul li {
      padding-bottom: 6px; }
      .widget-container.widget_nav_menu ul li.nav-copyright, .widget-container.widget_categories ul li.nav-copyright, .widget-container.widget_product_categories ul li.nav-copyright, .widget-container.widget_recent_entries ul li.nav-copyright {
        color: #999999;
        font-weight: 300; }
      .widget-container.widget_nav_menu ul li input[type="checkbox"], .widget-container.widget_categories ul li input[type="checkbox"], .widget-container.widget_product_categories ul li input[type="checkbox"], .widget-container.widget_recent_entries ul li input[type="checkbox"] {
        opacity: 0;
        position: absolute; }
      .widget-container.widget_nav_menu ul li a, .widget-container.widget_categories ul li a, .widget-container.widget_product_categories ul li a, .widget-container.widget_recent_entries ul li a {
        color: #242626;
        text-transform: capitalize; }
        .widget-container.widget_nav_menu ul li a:hover, .widget-container.widget_categories ul li a:hover, .widget-container.widget_product_categories ul li a:hover, .widget-container.widget_recent_entries ul li a:hover {
          color: #7fc9c4; }
        .widget-container.widget_nav_menu ul li a.active, .widget-container.widget_categories ul li a.active, .widget-container.widget_product_categories ul li a.active, .widget-container.widget_recent_entries ul li a.active {
          color: #e0545f;
          display: block;
          position: relative; }
          .widget-container.widget_nav_menu ul li a.active:before, .widget-container.widget_categories ul li a.active:before, .widget-container.widget_product_categories ul li a.active:before, .widget-container.widget_recent_entries ul li a.active:before {
            content: "";
            display: block;
            width: 9px;
            height: 1px;
            background: #e0545f;
            position: absolute;
            top: 13px;
            right: 5px;
            transform: rotateZ(45deg); }
          .widget-container.widget_nav_menu ul li a.active:after, .widget-container.widget_categories ul li a.active:after, .widget-container.widget_product_categories ul li a.active:after, .widget-container.widget_recent_entries ul li a.active:after {
            content: "";
            display: block;
            width: 1px;
            height: 9px;
            background: #e0545f;
            position: absolute;
            top: 9px;
            right: 9px;
            transform: rotateZ(45deg); }
      .widget-container.widget_nav_menu ul li .count, .widget-container.widget_categories ul li .count, .widget-container.widget_product_categories ul li .count, .widget-container.widget_recent_entries ul li .count {
        padding-left: 17px;
        font-size: 12px;
        color: #cacaca; }
  .widget-container.widget_tag_cloud li {
    display: inline-block; }
  .widget-container.widget_tag_cloud a {
    font-size: 14px !important;
    line-height: normal !important;
    display: inline-block;
    margin: 0 20px 20px 0;
    color: #666; }
    .widget-container.widget_tag_cloud a:hover {
      color: #000000; }
  .widget-container.widget_price_filter .ui-slider {
    position: relative;
    background: #f6f6f6;
    margin-bottom: 40px;
    height: 2px;
    margin-left: 4px; }
    .widget-container.widget_price_filter .ui-slider .ui-slider-range {
      background: #000000;
      height: 100%;
      position: absolute; }
    .widget-container.widget_price_filter .ui-slider .ui-slider-handle {
      height: 14px;
      width: 4px;
      background: #000000;
      position: absolute;
      top: -6px;
      cursor: ew-resize;
      margin-left: -4px; }
  .widget-container.widget-hover-content .widget-hover-element {
    visibility: hidden;
    opacity: 0;
    transition: all 0.4s ease-in-out; }
  .widget-container.widget-hover-content:hover .widget-hover-element {
    visibility: visible;
    opacity: 1; }

/* ========================================
12. Popup Newletter
======================================== */
#email-modal {
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999999; }

#email-modal .modal-overlay {
  background: rgba(45, 45, 50, 0.8);
  height: 100%;
  position: absolute;
  width: 100%; }

#email-modal .modal-window {
  min-height: 320px;
  margin: 0 auto;
  position: relative;
  top: 25%;
  width: 797px; }

#email-modal .window-window {
  position: relative; }

#email-modal .window-window .window-background {
  opacity: 0.8;
  filter: alpha(opacity=80); }

#email-modal .window-window .window-content {
  position: relative;
  max-height: 370px;
  overflow: hidden;
  background: #fff;
  float: left; }

#email-modal .window-window .window-content .left {
  width: 317px;
  float: left; }

#email-modal .window-window .window-content .right {
  width: 478px;
  float: right;
  padding: 40px 30px; }

#email-modal .window-window .window-content .btn.close,
.close-window,
.close-modal {
  padding: 0;
  height: 25px;
  width: 25px;
  position: absolute;
  right: 2px;
  top: 2px;
  font-weight: 400;
  z-index: 9;
  line-height: 24px;
  border: 0;
  background: none;
  cursor: pointer;
  filter: alpha(opacity=100);
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)"; }

#email-modal .window-window .window-content .btn.close:hover,
.close-window:hover,
.close-modal:hover {
  color: #e95144; }

#email-modal .window-window .window-content .title {
  font-size: 34px;
  text-align: left;
  position: relative;
  font-family: "Montserrat",Helvetica,Arial,sans-serif;
  font-weight: 700;
  letter-spacing: 0em;
  margin-bottom: 8px; }

#email-modal .window-window .window-content .sub-title {
  margin-bottom: 15px;
  font-size: 15px;
  letter-spacing: 0.05em;
  color: #555555; }

#email-modal .window-window .window-content .message {
  margin-top: 0 !important;
  clear: both;
  padding: 4px 0 23px;
  margin-bottom: 0px;
  letter-spacing: 0.02em;
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 20px; }

/* MAIL CHIMP FORM INPUTS ---------------------------------------------------------- */
#mce-EMAIL {
  border: 1px solid #000;
  color: #333;
  font: 11px arial, sans-serif;
  height: 11px;
  margin: 0 !important;
  padding: 8px !important;
  width: 217px; }

#mailchimp-email-subscibe {
  overflow: hidden; }

#mc_embed_signup {
  clear: left;
  overflow: hidden; }

#mc_embed_signup form {
  display: inline !important;
  padding: 0 !important;
  text-align: center !important; }

#mc_embed_signup input.input-group-field {
  display: block;
  border: none;
  width: 255px;
  background: #f5f5f5;
  color: #a3a3a3;
  font-style: italic;
  height: 36px;
  float: left;
  padding: 5px 10px;
  line-height: 36px;
  margin-right: 10px; }

#mc_embed_signup button {
  float: right;
  margin-left: -3px;
  border: none;
  background: none;
  padding: 0; }

#mc_embed_signup .input-group-btn .btn {
  display: block;
  margin: 0; }

#mc_embed_signup .input-group-btn {
  float: left; }

#mc_embed_signup .input-group-btn .btn {
  padding: 0 19px !important; }

#email-modal .product-socials {
  padding: 0;
  border-top: 0 none;
  border-bottom: 0 none;
  text-align: left;
  margin: 0; }

#email-modal .subscible-form .form-control {
  border: 1px solid #ccc;
  height: 40px;
  border-radius: 0px;
  width: 100%; }

#email-modal .form-group {
  margin-bottom: 0;
  padding: 0; }

.form-submit.inline-block {
  text-align: left; }

.form-submit.inline-block .btn-primary {
  padding: 10px 20px;
  border: 1px solid #666;
  border-radius: 0px;
  margin-top: 15px;
  color: #333;
  background: #fff;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600; }

.form-submit.inline-block .btn-primary:hover {
  color: #fff;
  background: #444; }

@media (max-width: 800px) {
  #email-modal .modal-window {
    width: 750px; }

  #email-modal .window-window .window-content .left {
    padding: 20px 40px; }

  #email-modal .window-window .window-content {
    float: none; }

  #email-modal .modal-window {
    width: 80%; }

  #email-modal .window-window .window-content .left {
    width: 100%; }

  #email-modal .window-window .window-content .right {
    display: none; } }
@media (max-width: 768px) {
  #email-modal .modal-window {
    width: 80%; }

  #email-modal .window-window .window-content .left {
    width: 100%; }

  #email-modal .window-window .window-content .right {
    display: none; } }
@media (max-width: 768px) {
  #email-modal .form-group {
    width: 100%; }

  #email-modal .form-group {
    padding: 15px;
    padding-top: 0;
    padding-bottom: 0; }

  #email-modal .form-submit {
    width: 100%; }
    #email-modal .form-submit .btn {
      width: 100%; }

  #email-modal .product-socials li > a {
    font-size: 15px; } }
@media (max-width: 480px) {
  #email-modal .form-group {
    width: 100%; }

  #email-modal .form-submit {
    width: 100%; }
    #email-modal .form-submit .btn {
      width: 100%; }

  #email-modal .product-socials li > a {
    font-size: 15px; } }
@media (max-width: 320px) {
  #email-modal .modal-window {
    width: 90%;
    top: 10%; }

  #email-modal .window-window .window-content .left {
    padding: 20px; } }
/* ========================================
13. Owl Quickview
======================================== */
/* clearfix */
.owl-carousel .owl-wrapper:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }

/* display none until init */
.owl-carousel {
  display: none;
  position: relative;
  width: 100%;
  -ms-touch-action: pan-y; }

.owl-carousel .owl-wrapper {
  display: none;
  position: relative;
  -webkit-transform: translate3d(0px, 0px, 0px); }

.owl-carousel .owl-wrapper-outer {
  overflow: hidden;
  position: relative;
  width: 100%; }

.owl-carousel .owl-wrapper-outer.autoHeight {
  -webkit-transition: height 500ms ease-in-out;
  -moz-transition: height 500ms ease-in-out;
  -ms-transition: height 500ms ease-in-out;
  -o-transition: height 500ms ease-in-out;
  transition: height 500ms ease-in-out; }

.owl-carousel .owl-item {
  float: left;
  margin-right: 5px; }

.owl-controls .owl-page,
.owl-controls .owl-buttons div {
  cursor: pointer; }

.owl-controls {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

/* fix */
.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0); }

/* ========================================
14. Popup Ajax AddtoCart
======================================== */
.engo-popup {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  align-items: center;
  position: fixed;
  z-index: 9999;
  top: 0;
  height: 0;
  width: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out; }
  .engo-popup.wishlist-popup .btn-cart {
    display: none; }
  .engo-popup.loading {
    z-index: 999; }
  .engo-popup .overlay {
    background-color: rgba(0, 0, 0, 0.3);
    color: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0 !important;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 0;
    height: 0; }
  .engo-popup .content {
    max-width: 800px;
    padding: 20px;
    margin: 0 auto;
    text-align: left;
    position: relative;
    background: #fff;
    min-width: 490px;
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    -ms-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out; }
  @media (max-width: 1500px) {
    .engo-popup .content {
      max-width: 700px; } }
  @media (max-width: 1300px) {
    .engo-popup .content {
      max-width: 650px; } }
  .engo-popup.active {
    visibility: visible;
    opacity: 1;
    top: 0;
    height: auto;
    width: auto; }
    .engo-popup.active .content {
      max-height: 100%; }
    .engo-popup.active .overlay {
      width: 100%;
      height: 100%; }
  .engo-popup .close-window {
    position: absolute;
    right: 0;
    top: 0;
    font-weight: bold;
    color: #000;
    width: 24px;
    height: 24px;
    text-align: center; }
    .engo-popup .close-window i {
      line-height: 24px; }
  .engo-popup .success-message:hover:before {
    background: #ffb533;
    color: #fff; }
  .engo-popup .success-message:before {
    font-family: FontAwesome;
    content: "\f00c";
    display: inline-block;
    margin-right: 10px;
    font-size: 12px; }
  .engo-popup.loading .loader {
    left: 50%;
    position: fixed;
    top: 50%;
    -moz-transform: translateY(-50%) translateX(-50%);
    -webkit-transform: translateY(-50%) translateX(-50%);
    -o-transform: translateY(-50%) translateX(-50%);
    -ms-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%); }
  .engo-popup .product-name {
    font-weight: 700;
    font-family: Montserrat, Arial, sans-serif; }

.cart-popup .btn-wishlist {
  display: none; }

.f-left {
  float: left; }

.mt-100 {
  margin-top: 100px; }

.mr-10 {
  margin-right: 10px; }

.pr-20 {
  padding-right: 20px; }

/*Hidden Ajax Popup AddtoCart Mobile*/
@media screen and (max-width: 480px) {
  .engoc_hide_mobile {
    display: none !important; } }
/*Max height block minicart-miniwishlist*/
.engoc_max_height {
  max-height: 380px;
  overflow: auto; }

@media screen and (max-width: 480px) {
  .engoc_max_height {
    max-height: 260px;
    overflow: auto; } }
/* Custom SVG Logo */
svg {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  border: 0; }

/* Config sticky menu Header Style 1 */
.awemenu-sticky {
  left: 0;
  right: 0;
  top: 0;
  position: static; }

.awemenu-mobile-outleft.awemenu-sticky, .awemenu-sticky.awemenu-mobile-outright {
  position: static !important; }

/* Config sticky menu Header Style 2 */
/* Config sticky menu Header Style 3 */
/*Custom input Comming Soon*/
input[type="password"].engoc_passw::-webkit-input-placeholder {
  color: #898989; }

input[type="password"].engoc_passw {
  background-color: transparent;
  color: #fff;
  outline: none; }

input[type="password"].engoc_passw:focus {
  box-shadow: none;
  border-color: #fff; }

#login_form {
  position: relative;
  margin: auto; }
  @media only screen and (min-width: 768px) {
    #login_form {
      width: 450px; } }

.engoc_action {
  position: absolute;
  top: 0px;
  right: 0px; }

input[type="submit"].engoc_submit {
  text-indent: -99999px;
  background-color: transparent;
  background-image: url("cms_icon.png"); }

.errors ul li {
  list-style: none;
  color: red; }

/*Hover banner*/
.engoc_hover_img {
  overflow: hidden; }
  .engoc_hover_img:hover {
    background: #000; }
    .engoc_hover_img:hover img {
      transition: all 0.5s ease 0s;
      transform: translate(-50%, -50%) scale(1.1);
      opacity: 0.5; }

.engoc_hover_ins {
  overflow: hidden; }
  .engoc_hover_ins i {
    position: absolute;
    top: 0px;
    right: 0px;
    left: 0px;
    bottom: 0px;
    margin: auto;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    color: #000;
    background: #fff;
    text-align: center;
    font-size: 25px;
    line-height: 50px;
    opacity: 0;
    z-index: 9999; }
  .engoc_hover_ins:hover {
    background: #000; }
    .engoc_hover_ins:hover i {
      opacity: 1; }
    .engoc_hover_ins:hover img {
      transition: all 0.5s ease 0s;
      transform: translate(-50%, -50%) scale(1.1);
      opacity: 0.5; }

.engoc_hover_banner {
  overflow: hidden; }
  .engoc_hover_banner:hover {
    background: #000; }
    .engoc_hover_banner:hover img {
      transition: all 0.5s ease 0s;
      transform: scale(1.1);
      opacity: 0.5; }

/*BacktoTop*/
#engo_backtotop {
  width: 50px;
  height: 50px;
  background: #242426;
  color: #fff;
  text-align: center;
  position: fixed;
  right: 30px;
  bottom: 30px;
  z-index: 100;
  cursor: pointer;
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
  -khtml-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
  -ms-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
  -o-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12); }

#engo_backtotop:hover {
  background: #7fc9c4; }

#engo_backtotop i {
  font-size: 18px;
  line-height: 50px;
  -webkit-transition: all 0.4s ease;
  -khtml-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease; }

#engo_backtotop:hover i {
  -webkit-transform: scale(1.3);
  -khtml-transform: scale(1.3);
  -moz-transform: scale(1.3);
  -ms-transform: scale(1.3);
  -o-transform: scale(1.3);
  transform: scale(1.3); }

/* Social Footer Mobile Icon */
@media only screen and (max-width: 480px) {
  footer .nav-social a span, .nav-social ul a span {
    display: none; }

  footer .nav-social a i, .nav-social ul a i {
    font-size: 25px;
    letter-spacing: 15px; } }
/* Padding right Page Demo */
@media only screen and (min-width: 480px) {
  .engo_pdm_right .widget {
    padding-left: 30px;
    padding-right: 0px; } }
/*Price of Product detail*/
#ProductPrice label, .engoc_subtt_qw {
  color: #212121;
  margin: 0 10px 5px 0;
  font-size: 14px;
  font-weight: 400; }

/*Mobile Blog Masonry*/
@media screen and (max-width: 480px) {
  .br-card.engoc_blog_masonry_mobile {
    width: 100% !important; } }
/*Fix icon search ipad header 3*/
@media (min-width: 769px) and (max-width: 1024px) {
  .form-search button {
    display: none; } }
/* Product search - Auto complate */
.engoc-header-s3 .form-search button, .engoc-header-s3 .form-search .btn, .engoc-header-s3 .form-search button, .engoc-header-s3 .form-search
input[type="submit"], .engoc-header-s3 .form-search
input[type="reset"], .engoc-header-s3 .form-search
input[type="button"] {
  top: 28px; }

#productSearchResults {
  border-top: 1px #dfdfdf solid;
  background: #fff; }
  .engoc-header-s3 #productSearchResults {
    border: 1px #dfdfdf solid; }
  #productSearchResults ul {
    display: block;
    margin: 0;
    padding: 5px 0;
    overflow-y: auto;
    max-height: 472px; }
    #productSearchResults ul li {
      display: block;
      border-bottom: 1px solid #efefef;
      padding: 5px; }
      #productSearchResults ul li:last-child {
        border-bottom: none; }
        #productSearchResults ul li:last-child > p {
          padding: 0 15px; }
      #productSearchResults ul li:after {
        display: block;
        content: "";
        clear: both; }
      #productSearchResults ul li:nth-of-type(2) {
        /*background: #fdfdfd;*/ }
      #productSearchResults ul li .hightlight {
        background-color: #7fc9c4;
        color: #fff;
        padding: 0 1px; }
      #productSearchResults ul li .search-item-title {
        display: block; }
      #productSearchResults ul li img {
        max-width: 60px;
        float: left;
        margin-right: 10px; }
      #productSearchResults ul li .item-price {
        font-weight: bold;
        margin-right: 5px; }
      #productSearchResults ul li del {
        color: #bcbcbc; }
      #productSearchResults ul li .item-sold-out {
        background: #bcbcbc;
        color: #fff;
        padding: 0 2px;
        border-radius: 2px;
        font-size: 11px; }

/* svg soul out */
.swatch label {
  border: none; }

.crossed-out {
  position: absolute;
  width: 25px;
  height: 25px;
  left: 0;
  top: 0; }
  .crossed-out svg {
    max-width: 50%;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);
    margin: 50%; }
  .crossed-out:hover {
    cursor: default; }

/* products-recently */
.products-recently {
  border-top: 1px solid #e5e5e5;
  padding-top: 38px; }
  .products-recently .slick-slider .slick-prev {
    left: -25px; }
  .products-recently .slick-slider .slick-next {
    right: -25px; }
  @media (max-width: 1023px) {
    .products-recently .slick-slider .slick-prev {
      left: 0; }
    .products-recently .slick-slider .slick-next {
      right: 0; } }

/* Config Fontsize content in Slider on Mobile */
@media screen and (max-width: 768px) {
  .slide-banner-slide-5 h2 {
    font-size: 50px !important; } }
/* Custom select inline span */
select {
  width: auto; }

.br-filter-wrapper .br-filter-group--show span {
  margin-right: 20px; }
.br-filter-wrapper .br-filter-group--sort span {
  margin-right: 20px; }

#engo_backtotop {
  background: #4A5338; }

#engo_backtotop:hover {
  background: #7E8D61; }

.nav_container .logo {
  padding: 10px 39px 15px 150px; }

.footer_v1 .footer_wrap .footer_top {
  padding-bottom: 40px;
  padding-top: 40px; }

.content_sidebar_grid.content_sidebar .category_grid .category_sidebar_grid li::before {
  display: none; }

.content_sidebar_grid.content_sidebar .category_grid .category_sidebar_grid li {
  border-bottom: 0 dashed #ccc; }

.section .grid {
  padding-top: 10px; }

.latest_post .latest-posts-body .latest-posts-meta .note, .latest_post .latest-posts-body .latest-posts-meta .more a, .default .hentry-wrap .entry-info .entry-content {
  font-family: sans-serif !important; }

.nav_container nav.collapse ul li a.active {
  color: #677718; }

.footer_v1 .footer_wrap .footer_top .footer-menu ul.footer-nav li a:hover {
  color: #7e8d61 !important; }

#shopify-section-footer {
  margin-top: 40px; }
